export function getArticleType(type?: string | null): string | null {
  if (typeof type !== 'string') {
    return null;
  }

  if (type === 'podcast') {
    return 'audio';
  }

  return type;
}
