import {
  isHomePage,
  isExtraPage,
  isSectionPage,
  isTagPage,
  isSearchPage,
  isAuthorProfilePage,
  isArticleContext,
  type MainContentQueryResultData,
} from '@hubcms/domain-cook';

type GetPageHrefProps = {
  data: MainContentQueryResultData;
  isCanonical?: boolean;
};

export const getPageHref = ({ data, isCanonical = false }: GetPageHrefProps): string | null => {
  if (data.resolution) {
    if (isHomePage(data) || isSectionPage(data)) {
      return data.resolution.section.href;
    }
    if (isTagPage(data) || isSearchPage(data)) {
      return `${data.resolution.section.href}${data.resolution.remainingPath}`;
    }
  }
  if (data.context) {
    if (isExtraPage(data)) {
      return data.context.href;
    }

    if (isAuthorProfilePage(data)) {
      // add a trailing slash for the canonical
      return `${data.context.href}${isCanonical ? '/' : ''}`;
    }

    if (isArticleContext(data.context)) {
      return isCanonical && data.context.fields.canonicalUrlOverride
        ? data.context.fields.canonicalUrlOverride
        : data.context.href;
    }
  }
  return null;
};
