import { isNonNull } from '@hubcms/utils-browser';

type SimpleAuthor = string | null | undefined;

type Author =
  | {
      name: SimpleAuthor;
      [key: string]: unknown;
    }
  | SimpleAuthor;

export function getArticleAuthor(authors: Author[] = []) {
  if (!authors.length) {
    return null;
  }

  return authors
    .map(author => (typeof author === 'string' ? author : author?.name))
    .filter(isNonNull)
    .join(',');
}
