import { type CookData, isArticleContext, isHomePage, isSectionPage, isExtraPage } from '@hubcms/domain-cook';
import { extractSectionPath, getPageType } from '@hubcms/utils-tracking';
import { getPageHref } from '@hubcms/feature-cook';

import type { PageParams } from '../domain/page-params';

function determineSectionTree(cookData: CookData, brandcode: string) {
  let sectionTreeArr: string[] = [];
  let allSections: Array<{ href: string }> = [];

  if (isArticleContext(cookData.context)) {
    allSections = cookData.context.allSections;
  } else if (isSectionPage(cookData) || isHomePage(cookData) || isExtraPage(cookData)) {
    allSections = [{ href: cookData.resolution.section.href }];
  }

  if (allSections?.length) {
    sectionTreeArr = allSections.map(el => extractSectionPath(el.href, brandcode));
  }

  return sectionTreeArr;
}

export function getPageSecureData(cookData: CookData) {
  const isPremium = isArticleContext(cookData.context) ? cookData.context.fields.isPremium : false;

  return {
    pagesecuretype: isPremium ? 'plus' : 'open',
    pagesecure: isPremium,
  } as const;
}

export function getPageParams(brandcode: string, pagetitle: PageParams['pagetitle'], cookData?: CookData): PageParams {
  const pageParams: PageParams = {};

  pageParams.pagetitle = pagetitle;

  if (cookData) {
    const { pagesecure, pagesecuretype } = getPageSecureData(cookData);
    pageParams.pagesecure = pagesecure;
    pageParams.pagesecuretype = pagesecuretype;
    pageParams.pagetype = getPageType(cookData);
    pageParams.pagesectiontrees = determineSectionTree(cookData, brandcode);
    if (isHomePage(cookData)) {
      pageParams.pagesectionname = brandcode;
    } else {
      pageParams.pagesectionname = cookData.resolution?.section?.name.toLowerCase();
    }

    const canonicalUrl = getPageHref({ data: cookData, isCanonical: true });
    if (canonicalUrl) {
      pageParams.canonicalurl = canonicalUrl;
    }
  }

  if (typeof document !== 'undefined') {
    pageParams.pageurl = document.location.href;
    pageParams.referrer = document.location.href.includes('utm_') ? null : document.referrer;
    pageParams.referrerhost = document.referrer ? new URL(document.referrer).hostname : '';
  } else {
    pageParams.pageurl = null;
    pageParams.referrer = null;
    pageParams.referrerhost = null;
  }

  return pageParams;
}
