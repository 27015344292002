import type { RecommendationParams } from '../domain/recommendation-params';

export function getRecommendationParams(): RecommendationParams {
  const recommendationParams: RecommendationParams = {};

  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);
    const recommender = urlParams.get('recommender');

    if (window.document.referrer.includes(window.origin) && recommender) {
      recommendationParams.recommendationsource = urlParams.get('recommender') || '';
    }
  }

  return recommendationParams;
}
