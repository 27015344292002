import { error } from '@hubcms/utils-monitoring';

type ArticleCounts = {
  articleBodyCharacterCount: number | null;
  articleWordCount: number | null;
};

const EMPTY_ARTICLE_COUNTS: ArticleCounts = {
  articleBodyCharacterCount: null,
  articleWordCount: null,
};

export function getArticleCounts(articleCounts: string | null): ArticleCounts {
  if (!articleCounts) {
    return EMPTY_ARTICLE_COUNTS;
  }

  try {
    const parsedArticleCounts = JSON.parse(articleCounts) as {
      articleBodyCharacterCount: number;
      articleWordCount: number;
    };

    return {
      articleBodyCharacterCount: parsedArticleCounts.articleBodyCharacterCount ?? null,
      articleWordCount: parsedArticleCounts.articleWordCount ?? null,
    };
  } catch (err) {
    error(`Error parsing articleCounts: ${articleCounts}`, err);
    return EMPTY_ARTICLE_COUNTS;
  }
}
